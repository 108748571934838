::-webkit-scrollbar {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  background-color: #18181b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

li ol {
  margin-left: 2em;
}
/*# sourceMappingURL=index.01eaf068.css.map */
